<template>
  <div>
    <v-img
      class="bg-image"
      :src="require(`@/assets/images/homebg.jpg`)"
    ></v-img>
    <v-col
      cols="12"
      class="mx-auto"
    >
    <v-card class="home-body" flat style="background-color: transparent !important;">
      <v-card-text id="home-title" class="text-center text-uppercase" >bienvenidos a nuestro sistema personalizado</v-card-text>
      <div class="logo-container">
          <img src="@/assets/images/logo.png" alt="">
      </div>
      <v-card-text id="home-subtitle" class="text-center text-uppercase">dedicados a brindar cada día el mejor servicio a nuestros colegas y clientes</v-card-text>
    </v-card>
    </v-col>
    <v-col v-if="userData.simulator" cols="12" class="d-flex justify-center">
      <v-btn
        plain
        color="primary"
        class="mt-4 ps-4"
        @click="$router.push('/simulator')"
      >
        <strong>Probar el Simulador de Costo de Análisis</strong>
      </v-btn>
    </v-col>
  </div>
</template>

<script>

export default {
  computed: {
    userData() { return this.$store.getters['session/userData']() },
  }
}
</script>

<style scoped>

.bg-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

#home-title{
  color: black;
  letter-spacing: 3px !important;
  font-size: 25px;
  margin-bottom: 3vh;
}

#home-subtitle{
  margin-top: 5vh;
  color: black;
  letter-spacing: 1px !important;
  font-size: 20px;
}
.logo-container {
  margin-top: 2vh;
  margin-bottom: 4vh;
  display: flex;
  justify-content: center;
}

  /* Height Adjustment */
@media (max-height: 800px) {
  .logo-container{
    margin-top: 0vh;
    margin-bottom: 2vh;
  }
  .home-body{
    margin-top: -50px;
    font-size: 60%;
  }

  #home-title{
    font-size: medium;
  }

  #home-subtitle{
    font-size: small;
    padding-bottom: 0px;
  }
  .logo-container img{
    width:30%;
  }
}
</style>
